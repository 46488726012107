import { Box, style } from "@mui/system";
import React from "react";

import OfferPte from "./OfferPte";
import Subscription from "./Subscription";
 
function LayoutOS() {
  //custom css and responsive value
  const layoutStyle = {
    // backgroundColor: "rgb(227,242,253)",
    backgroundColor :"FFF",
    width: "100%",
    position:'static'
  };


  
  return (
    <>
      {/* layout box for offer and subscription section */}
      <Box
        sx={{
          ...layoutStyle,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: { xs: "30.5%", md: "30%", xl: "37%",lg:"31%" }, // Adjust for responsiveness

            left: { xs: "87%", md: "-1%", xl: "-1%",lg:"1%" }, // Adjust for responsiveness
            transform: "translateY(-10%)",
            m: 0,
            // backgroundColor: "#0f0",
            // p: 1,
          }}
        >
          <Box
            sx={{
                width: {
                  xs: "15vw",
                  sm: "12vw",
                  md: "15vw",
                  lg: "14vw",
                  xl: "10vw",
                },
                overflow: "hidden",
                // display: "flex",
                // justifyContent: "end",
                // alignItems: "end",
              }}
            >
              <img
                src={`${process.env.REACT_APP_FRONTEND_URL}Image/water1.png`}
                style={{ width: "100%", height: "auto", transform: "rotate(350deg)" }}
                alt="Christmas Tree"
              />
          </Box>

        </Box>
  
        <OfferPte></OfferPte>
        <Box
          sx={{
            position: "absolute",
            top: { xs: "30.5%", md: "36%", xl: "39%",lg:"36%" }, // Adjust for responsiveness

            left: { xs: "87%", md: "88%", xl: "91%",lg:"88%" }, // Adjust for responsiveness
            transform: "translateY(-10%)",
            m: 0,
            // backgroundColor: "#0f0",
            // p: 1,
          }}
        >
          <Box
            sx={{
                width: {
                  xs: "15vw",
                  sm: "12vw",
                  md: "13vw",
                  lg: "12vw",
                  xl: "9.1vw",
                },
                overflow: "hidden",
                // display: "flex",
                // justifyContent: "end",
                // alignItems: "end",
              }}
            >
              <img
                src={`${process.env.REACT_APP_FRONTEND_URL}Image/water1.png`}
                style={{ width: "100%", height: "auto", transform: "rotate(180deg)" }}
                alt="Christmas Tree"
              />
          </Box>

        </Box>
      </Box>
    </>
  );
}

export default LayoutOS;
